<template>
  <div>
    <!-- BREADCRUMB -->
    <section
      class="breadcrumb-bg"
      style="
        background-image: url(../assets/img/background/page-title-bg-img.jpg);
      "
    >
      <div class="container">
        <div class="breadcrumb-holder">
          <div>
            <h1 class="breadcrumb-title">會員中心</h1>
            <ul class="breadcrumb breadcrumb-transparent">
              <li class="breadcrumb-item">
                <a class="text-white" href="/">Home</a>
              </li>
              <li class="breadcrumb-item text-white active" aria-current="page">
                我的兌換明細
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <!-- content -->
    <section class="py-6 py-md-8">
      <div class="container">
        <div class="row">
          <div class="col-md-4 col-lg-4">
            <div class="card bg-warning card-hover mb-3">
              <div class="card-body text-center px-md-5 px-lg-6 my-2">
                <div class="card-icon-border-large border-warning mtn-80">
                  <img src="@/assets/img/avator/avator-img1.jpg" alt="testimonial1.jpg" v-if="reloginImg === 'null'">
                  <img :src="reloginImg" alt="">
                </div>
                <blockquote class="blockquote blockquote-sm mt-2">
                  <!-- <label class="btn btn-link">
                    <input id="upload_img" style="display: none" type="file" />
                    <i class="fa fa-plus-circle"></i> <span>上傳圖片</span>
                  </label> -->

                  <footer
                    class="blockquote-footer text-uppercase text-white font-size-20 mb-3"
                  >
                    {{ gradeTitle }}
                  </footer>
                  <ul class="member__level-buttons">
                    <li v-for="(item, index) in gradeMap" :key="index" class="m-1">
                      <button @click="$router.push(item.link)" :disabled="form.grade < item.grade" type="button" class="btn btn-light mb-1">
                        {{ `${item.title}測驗` }}
                      </button>
                    </li>
                  </ul>
                </blockquote>
              </div>
            </div>

            <ul class="list-unstyled mb-0">
              <li class="mb-2">
                <router-link to="/member"
                  class="text-muted font-weight-medium d-block border rounded py-2 pl-3">
                  基本資料
                </router-link>
              </li>
              <li class="my-2">
                <router-link :to="grade === 0? '/fqtest' : '/member/myfqage'"
                  class="text-muted font-weight-medium d-block border rounded py-2 pl-3">
                  我的FQ年齡
                </router-link>
              </li>
              <li class="my-2">
                <router-link to="/member/mypoints"
                  class="text-muted font-weight-medium d-block border rounded py-2 pl-3">
                  我的$幣存摺
                </router-link>
              </li>
              <li class="my-2">
                <router-link to="/member/mall"
                  class="text-muted font-weight-medium d-block border rounded py-2 pl-3">
                  $幣兌換中心
                </router-link>
              </li>
              <li class="my-2">
                <router-link to="/member/exchange"
                  class="text-warning font-weight-medium d-block border rounded py-2 pl-3">
                  $幣累兌明細
                </router-link>
              </li>
              <li class="my-2">
                <button type="button"
                  @click="logOut"
                  class="btn btn-block text-muted font-weight-medium  border rounded py-2 pl-3">
                  會員登出
                </button>
              </li>
            </ul>
          </div>
          <div class="col-md-8 col-lg-8 order-md-1">
            <form class="" action="index.html" method="post">
              <div class="card shadow-none">
                <div
                  class="card-header card-header-lg bg-warning text-white p-3 rounded-top d-flex justify-content-between align-items-baseline"
                >
                  <h4 class="font-weight-bold mb-0">我的＄幣：{{ totalPoints }} 元</h4>
                </div>
                <div
                  class="card-body border border-top-0 rounded-bottom-sm p-7"
                >
                  <!-- <div class="row">
                    <p>
                      你要查詢的時間：
                      <label for="from">從</label>
                      <input type="text" id="from" name="from" />
                      <label for="to">到</label>
                      <input type="text" id="to" name="to" />
                      <button class="btn btn-danger text-uppercase btn-sm ml-2">
                        送出
                      </button>
                    </p>
                  </div> -->
                  <div class="row">
                    <table class="table text-center mb-0">
                      <thead>
                        <tr>
                          <th
                            class="bg-warning text-white text-uppercase"
                            scope="col"
                          >
                            日期
                          </th>
                          <th
                            class="bg-success text-white text-uppercase"
                            scope="col"
                          >
                            兌換商品
                          </th>
                          <th
                            class="bg-danger text-white text-uppercase"
                            scope="col"
                          >
                            兌換＄幣
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-for="item in changeDetail">
                          <tr :key="item.id">
                            <td
                              class="bg-white align-middle text-muted font-weight-medium"
                            >
                              {{ item.created_at }}
                            </td>
                            <td class="bg-white text-muted">
                              <span
                                class="text-success d-block font-weight-bold font-size-18 mb-2"
                                >{{ item.description }}</span
                              >
                            </td>
                            <td class="bg-white text-muted">
                              <span
                                class="text-danger d-block font-weight-bold font-size-18 mb-2"
                                >{{ item.point }}</span
                              >
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                  <!-- PAGINATION -->
                  <section class="py-5">
                    <div class="container">
                      <nav aria-label="Page navigation example">
                        <nav aria-label="Page navigation example">
                          <ul
                            class="pagination justify-content-center align-items-center"
                          >
                            <li class="page-item">
                              <a class="page-link" href="#" @click.prevent="getExchangeDetail(currentPage - 1)">
                                <i class="fa fa-arrow-left mr-1" aria-hidden="true"></i>Prev
                              </a>
                            </li>
                            <li class="page-item" v-for="item in totalPage" :key="item">
                              <a class="page-link" href="#" @click.prevent="getExchangeDetail()">{{ item }}</a>
                            </li>
                            <li class="page-item">
                              <a class="page-link" href="#" @click.prevent="getExchangeDetail(currentPage + 1)">Next
                                <i class="fa fa-arrow-right ml-1" aria-hidden="true"></i>
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </nav>
                    </div>
                  </section>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
/* global $ */
import tokenGenerator from '@/tokenGenerator.js'
import richkidConfig from '@/assets/json/richkid.config.json'
export default {
  name: 'exchange',
  data () {
    return {
      changeDetail: [],
      totalPoints: '',
      totalPage: '',
      currentPage: '',
      reloginImg: '',
      gradeTitle: '',
      form: {},
      grade: ''
    }
  },
  created () {
    const vm = this

    if (localStorage.getItem('memberToken') === '') {
      alert('請先登入')
      vm.$router.push('/login')
      return false
    }

    vm.getExchangeDetail()
    vm.getPoints()
    vm.getMember()
    vm.reloginImg = localStorage.getItem('reloginImg')
    vm.gradeTitle = sessionStorage.getItem('gradeTitle')
  },
  mounted () {
    require('@/assets/js/jquery-ui-1.12.1.js')
    $(function () {
      $('#from').datepicker({
        defaultDate: '+1w',
        numberOfMonths: 1,
        onClose: function (selectedDate) {
          $('#to').datepicker('option', 'minDate', selectedDate)
        }
      })
      $('#to').datepicker({
        defaultDate: '+1w',
        numberOfMonths: 1,
        onClose: function (selectedDate) {
          $('#from').datepicker('option', 'maxDate', selectedDate)
        }
      })
    })
  },
  computed: {
    gradeMap () {
      return richkidConfig.data.grade
    }
  },
  methods: {
    getMember () {
      const vm = this
      const api = `${process.env.VUE_APP_APIPATH}/iPlay/member/check`
      vm.memberToken = localStorage.getItem('memberToken')

      vm.axios.post(api, {
        token: tokenGenerator(),
        memberToken: vm.memberToken
      })
        .then((res) => {
          vm.form = res.data.content
          vm.grade = res.data.content.grade
        })
    },
    getExchangeDetail (pageNum = 1) {
      const vm = this
      const api = `${process.env.VUE_APP_APIPATH}/iPlay/bankbook/getBankBook`

      vm.axios.post(api, {
        token: tokenGenerator(),
        memberId: localStorage.getItem('memberId'),
        page: pageNum,
        pageSize: '10',
        type: ['5']
      })
        .then((res) => {
          vm.totalPage = res.data.totalPage
          vm.currentPage = res.data.currentPage
          vm.changeDetail = res.data.content
        })
    },
    getPoints () {
      const vm = this
      const api = `${process.env.VUE_APP_APIPATH}/iPlay/member/check`

      vm.axios.post(api, {
        token: tokenGenerator(),
        memberToken: localStorage.getItem('memberToken')
      })
        .then((res) => {
          vm.totalPoints = res.data.content.total_points
        })
    },
    logOut () {
      localStorage.removeItem('token')
      localStorage.removeItem('memberId')
      localStorage.removeItem('memberToken')
      localStorage.removeItem('reloginImg')

      location.href = '/'
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/css/jquery-ui.css";
  .member__level-buttons {
    padding-left: 0;
    list-style: none;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 1rem 0;
  }
</style>
